import React from "react";
import ReactDOM from "react-dom/client";
import { Parent1 } from "./router";
import "bootstrap/dist/css/bootstrap.min.css";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Parent1 />
  </React.StrictMode>
);
